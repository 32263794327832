import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const headingCarousel = document.querySelector(
    '.hg-parent-categories__headings__swiper'
  );

  if (headingCarousel) {
    new Swiper(headingCarousel, {
      slidesPerView: 'auto',
    });
  }
});
